<template>
    <div class="page-body clone-dashboard">
        <NoButtonModal :open="true">
            <div class="modal-content-header bottom-20">
                <span style="font-size: 20px; font-weight: bold; margin-left: 20px">Clone Dashboard</span>
            </div>
            <div v-if="!loading" class="d-flex flex-column align-items-center" style="gap: 10px">
                <div class="w-80 mx-4 d-flex justify-content-center flex-column">
                    <label for="company" class="add-new-app-label my-2 class-label">Select Company*</label>
                    <Multiselect
                        id="company"
                        track-by="id"
                        label="company_name"
                        class="position-relative"
                        placeholder="Search or select Company..."
                        v-model="companyData"
                        :options="companyList"
                        :searchable="true"
                        :allow-empty="false"
                        :show-labels="false"
                        @change="companyData = $event.target.value"
                        :disabled="loading || ['star-kid', 'mobex-health-plus'].includes(dashboardDetails.application)"
                        required
                    />
                </div>
                <div class="w-80 mx-4">
                    <Input
                        label="Dashboard Name"
                        name="Dashboard Name"
                        id="dashboardNameInput"
                        placeholder="Enter dashboard name"
                        v-model="dashboardName"
                        inputClass="input-add-new-dashboard mt-3"
                        labelClass="class-label"
                        :input_style="'text-transform:capitalize'"
                        required
                    />
                </div>
                <div class="my-4 d-flex justify-content-center" style="bottom: 10%">
                    <button type="button" class="primary btn-save" @click="cloneDashboard">Save Dashboard</button>
                    <button type="button" class="no-bg btn-cancel px-4" @click="$emit('cancel')">Cancel</button>
                </div>
            </div>
            <div v-if="loading" class="my-4 d-flex flex-column justify-content-center align-items-center loader-main">
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { companies, dashboardBuilder, memberProfile } from '../../util/apiRequests';

    export default {
        name: 'CloneDashboardModal',
        components: { NoButtonModal },
        data() {
            return {
                loading: true,
                dashboardName: '',
                companyData: '',
                companyList: [],
                colorScheme: {},
                newDashboardId: null,
            };
        },
        props: {
            dashboardDetails: {
                type: Object,
            },
        },
        methods: {
            async getAllCompanies() {
                try {
                    const endpoint = companies.getAllCompanies();
                    const response = await this.$api.get(endpoint);
                    const data = response?.data?.info;
                    this.companyList = data;
                    this.companyData = data.find((item) => item.id == this.$store.state?.user?.company_id);
                } catch (err) {
                    this.$toasted.error('Failed to fetch Company list');
                }
            },
            async cloneDashboard() {
                try {
                    this.loading = true;
                    if (!this.dashboardName && !this.companyData?.id) {
                        return this.$toasted.error('Dashboard Name is Required');
                    }
                    const res = await this.$api.post(dashboardBuilder.copyDashboard(this.dashboardDetails?.id), {
                        name: this.dashboardName,
                        secondaryCompanyId: this.companyData?.id,
                    });
                    if (res?.data?.success) {
                        if (this.companyData?.id === this.$store.state?.user?.company_id) {
                            this.$router.push({
                                name: 'DashboardBuilder',
                                query: { id: res?.data?.response },
                                replace: true,
                            });
                        } else {
                            if (this.dashboardDetails?.application === 'kiosk') {
                                await this.changeSvgsColor(res);
                            }
                            this.$toasted.success('Dashboard copied in another company');
                        }
                        this.$emit('cancel');
                    } else {
                        this.$toasted.error(res?.data?.error);
                    }
                } catch (err) {
                    this.$toasted.error(err);
                } finally {
                    this.loading = false;
                }
            },
            async changeSvgsColor(response) {
                const { svgImgList, subModuleSvgImg, color, dashboard_id } = response.data?.response;

                this.colorScheme = color;
                this.newDashboardId = dashboard_id;

                for (let i = 0; i < svgImgList.length; i++) {
                    const file = svgImgList[i].image.split('.');
                    const fileExtension = file[file.length - 1];
                    if (fileExtension == 'svg' || fileExtension == 'svg+xml') {
                        await this.urlToSvg(svgImgList[i]);
                    }
                }
                for (let i = 0; i < subModuleSvgImg.length; i++) {
                    const file = subModuleSvgImg[i].image.split('.');
                    const fileExtension = file[file.length - 1];
                    if (fileExtension == 'svg' || fileExtension == 'svg+xml') {
                        await this.urlToSvg(subModuleSvgImg[i]);
                    }
                }
            },
            async urlToSvg(imgObj) {
                const reader = new FileReader();
                let img = await fetch(imgObj.imageUrl);
                let imgBlob = await img.blob();
                reader.readAsDataURL(imgBlob);

                reader.onload = async () => {
                    const base64Data = reader.result.replace(/^data:image\/svg\+xml;base64,/, '');
                    const svgXml = atob(base64Data);
                    await this.renderSvg(svgXml, imgObj);
                };
            },
            async renderSvg(svgXml, imgObj) {
                // Set primary and secodary colors
                let primaryColor = this.colorScheme?.primaryColor;
                let secondaryColor = this.colorScheme?.secondaryColor;

                if (!primaryColor || !secondaryColor) {
                    return;
                }
                // Parse SVG XML string
                const parser = new DOMParser();
                const svgDoc = parser.parseFromString(svgXml, 'image/svg+xml');

                // Get the SVG element
                const svgElement = svgDoc.documentElement;

                const allPaths = svgElement.querySelectorAll('path');

                allPaths.forEach((path) => {
                    if (path.hasAttribute('fill')) {
                        let color = path.getAttribute('fill').split('#')[1].toLowerCase();
                        let colorType = path.getAttribute('id');
                        let colorTypeAvailable = false;
                        if (colorType === 'primaryColor') {
                            path.setAttribute('fill', primaryColor);
                            colorTypeAvailable = true;
                        } else if (colorType === 'secondaryColor') {
                            path.setAttribute('fill', secondaryColor);
                            colorTypeAvailable = true;
                        }
                        if (!colorTypeAvailable) {
                            if (
                                (color[0] === 'a' ||
                                    color[0] === 'b' ||
                                    color[0] === 'c' ||
                                    color[0] === 'd' ||
                                    color[0] === 'e' ||
                                    color[0] === 'f') &&
                                (color[2] === 'a' ||
                                    color[2] === 'b' ||
                                    color[2] === 'c' ||
                                    color[2] === 'd' ||
                                    color[2] === 'e' ||
                                    color[2] === 'f') &&
                                (color[4] === 'a' ||
                                    color[4] === 'b' ||
                                    color[4] === 'c' ||
                                    color[4] === 'd' ||
                                    color[4] === 'e' ||
                                    color[4] === 'f')
                            ) {
                                path.setAttribute('id', 'primaryColor');
                                path.setAttribute('fill', primaryColor);
                            } else {
                                path.setAttribute('id', 'secondaryColor');
                                path.setAttribute('fill', secondaryColor);
                            }
                        }
                    }
                });
                const svgHtml = svgElement.outerHTML;

                let filename = 'my-svg-file.svg';

                const svgData = new XMLSerializer().serializeToString(svgElement);

                const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });

                // Create a File object with the Blob and desired filename
                const svgFile = new File([svgBlob], filename, { type: 'image/svg+xml' });

                // Encode the SVG HTML string to base64
                let base64Svg = btoa(svgHtml);
                // Return the base64 encoded SVG data
                base64Svg = 'data:image/svg+xml;base64,' + base64Svg;

                const formData = new FormData();
                formData.append('id', imgObj.id);
                formData.append('file', svgFile);

                if (typeof imgObj?.isEmbedded === 'boolean') {
                    formData.append('isEmbedded', imgObj?.isEmbedded);
                }

                let res;

                //call the updateSvgImage API to store updated image
                if (imgObj.assigned_to) {
                    const endpoint = memberProfile.updateSubModuleSvgColor(this.companyData?.id);
                    res = await this.$api.post(endpoint, formData);
                } else {
                    const endpoint = memberProfile.updateSvgColor(this.companyData?.id, this.newDashboardId);
                    res = await this.$api.post(endpoint, formData);
                }
            },
        },
        async created() {
            await this.getAllCompanies();
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
    }

    .text-link:hover {
        background: #5155c3;
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .clone-dashboard .modal__content {
        padding: 0;
        border-radius: 10px;
        min-height: fit-content;
    }

    .clone-dashboard .class-label {
        color: #666c6d;
        font-size: 1rem;
        font-weight: 600;
    }
</style>
